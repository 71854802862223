<template>
  <div class="container-fluid px-lg-5 mb-3 margin-top-0421">
    <div class="row">
      <div class="col-sm-12 d-flex">
        <h1 class="d-inline-flex flex-grow-1 text-primary">My addresses</h1>
        <div class="d-inline-flex align-self-end">
          <a href="#" class="link-secondary fs-3" id="btnAddAddress" @click="btnAddAddressEvent">
            <i class="fa fa-plus"></i>
            Add new address
          </a>
        </div>
      </div>
    </div>
    <LoadingIcons v-if="$data.ajaxLoad.list" :total="10" />
    <div class="row" v-else>
      <div class="col-md-6 col-lg-3" v-for="(item, index) in $data.addressList" :key="index">
        <div class="card text-white bg-primary mb-3" v-if="item.set_default == 1">
          <div class="card-header d-flex">
            <div class="d-inline-flex flex-grow-1">Primary Address</div>
            <div class="d-inline-flex align-self-end">
              <a href="#" class="link-secondary" @click="editAddress($event, item.ID)"
                ><i class="fa fa-edit"></i
              ></a>
              &nbsp;<a href="#" class="link-danger" @click="deleteAddress($event, item.ID)"
                ><i class="fa fa-trash"></i
              ></a>
            </div>
          </div>
          <div class="card-body">
            <p class="card-text">
              {{ item.street_address_1 }} <br />
              <span v-if="item.street_address_2 != ''">{{ item.street_address_2 }} <br /></span>
              {{ item.city }} <br />
              {{ item.state }} - {{ item.zip_code }}<br />
              {{ item.country }} <br />
            </p>
          </div>
        </div>
        <div class="card bg-light mb-3" v-else>
          <div class="card-header d-flex justify-content-end">
            <div class="d-inline-flex align-self-end">
              <a href="#" class="link-primary" @click="btnSaveAsMainAddressEvent($event, item.ID)"
                >Set as primary
              </a>
              &nbsp;<a href="#" class="link-secondary" @click="editAddress($event, item.ID)">
                <i class="fa fa-edit"> </i>
              </a>
              &nbsp;<a href="#" class="link-danger" @click="deleteAddress($event, item.ID)"
                ><i class="fa fa-trash"></i
              ></a>
            </div>
          </div>
          <div class="card-body">
            <p class="card-text">
              {{ item.street_address_1 }} <br />
              <span v-if="item.street_address_2 != ''">{{ item.street_address_2 }} <br /></span>
              {{ item.city }} <br />
              {{ item.state }} - {{ item.zip_code }}<br />
              {{ item.country }} <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="modalAddress"
    tabindex="-1"
    aria-labelledby="modalAddressLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="modalAddressLabel" ref="modalAddressLabel">
            Add/Edit address
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <LoadingIcons v-if="$data.ajaxLoad.detail" />
          <ErrorFormField :msgArray="errors.common" v-if="errors.common.length > 0" />
          <div class="mb-3">
            <label for="street_address_1" class="form-label label-require"
              >House Number/Street Address</label
            >
            <input
              type="text"
              id="street_address_1"
              ref="gautocomplete"
              class="form-control"
              placeholder="Where do you live (City, State & Country)?"
              autocomplete="off"
            />
          </div>
          <ErrorFormField
            :msgArray="errors.street_address_1"
            v-if="errors.street_address_1.length > 0"
          />
          <div class="mb-3">
            <label for="street_address_2" class="form-label"
              >Apartment, unit, suite, or floor</label
            >
            <input
              type="text"
              ref="address2"
              class="form-control"
              id="street_address_2"
              placeholder="Apartment, unit, suite, or floor"
            />
          </div>
          <div class="mb-3">
            <label for="city" class="form-label label-require">City</label>
            <input type="text" ref="city" id="city" placeholder="City" class="form-control" />
          </div>
          <ErrorFormField :msgArray="errors.city" v-if="errors.city.length > 0" />
          <div class="row mb-3">
            <div class="col-md-6">
              <label for="state" class="form-label label-require">State/Province</label>
              <input type="text" ref="state" id="state" placeholder="State" class="form-control" />
              <ErrorFormField :msgArray="errors.state" v-if="errors.state.length > 0" />
            </div>

            <div class="col-md-6">
              <label for="zip_code" class="form-label label-require">Zip Code</label>
              <input
                type="text"
                ref="zip_code"
                id="zip_code"
                placeholder="Zip Code"
                class="form-control"
              />
              <ErrorFormField :msgArray="errors.zip_code" v-if="errors.zip_code.length > 0" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="zip_code" class="form-label label-require">Country/Region</label>
              <select
                class="form-select mb-3"
                aria-label=".form-select-lg example"
                id="country"
                ref="country"
                @change="countryChange"
              >
                <option value="" selected>Select a country</option>
                <option v-for="(item, index) in $data.countryList" :key="index" :value="item.code">
                  {{ item.name }}
                </option>
              </select>
              <ErrorFormField :msgArray="errors.country" v-if="errors.country.length > 0" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="set_default"
                  ref="set_default"
                />
                <label class="form-check-label" for="set_default"> Set as default </label>
              </div>
            </div>
          </div>
        </div>

        <input type="hidden" ref="lat" id="latitude" />
        <input type="hidden" ref="lng" id="longitude" />
        <input type="hidden" ref="editingAddressId" id="editingAddressId" />
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
          <LoadingIcons v-if="$data.ajaxLoad.normal" />
          <button
            type="button"
            class="btn btn-primary"
            id="modalSave"
            @click="btnModalSaveHandler"
            v-else
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fade"
    id="modalDeleteAddress"
    tabindex="-1"
    aria-labelledby="modalAddressLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h6 class="modal-title">Delete address</h6>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <h4>Do you want to remove this address</h4>
        </div>

        <input type="hidden" ref="deletetingAddressId" id="deletetingAddressId" />
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">No</button>
          <LoadingIcons v-if="$data.ajaxLoad.normal" />
          <button
            type="button"
            class="btn btn-primary"
            id="modalDelete"
            @click="btnModalDeleteHandler"
            v-else
          >
            Yes
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ErrorFormField from '../../common/error/ErrorFormField';
import LoadingIcons from '../../common/LoadingIcons';
var autocomplete;
export default {
  name: 'MyAddress',
  components: {
    ErrorFormField,
    LoadingIcons,
  },
  data: function () {
    return {
      errors: {
        common: [],
        street_address_1: [],
        city: [],
        state: [],
        zip_code: [],
        country: [],
      },
      ajaxLoad: {
        normal: false,
        detail: false,
        list: false,
      },
      countryList: [],
      addressList: [],
    };
  },
  methods: {
    btnModalSaveHandler: async function () {
      this.$data.ajaxLoad.normal = true;
      let apiUrl = process.env.VUE_APP_API_URL;

      let data = {
        unique_id: this.$store.state.unique_id,
        street_address_1: document.getElementById('street_address_1').value,
        street_address_2: document.getElementById('street_address_2').value,
        city: document.getElementById('city').value,
        state: document.getElementById('state').value,
        zip_code: document.getElementById('zip_code').value,
        country: document.getElementById('country').value,
        latitude: document.getElementById('latitude').value,
        longitude: document.getElementById('longitude').value,
        set_default: document.getElementById('set_default').checked ? 1 : 0,
      };
      var editingAddressId = this.$refs.editingAddressId.value;
      let url = '';
      if (editingAddressId == '') {
        //add new adderss
        url = 'user/add-address';
      } else {
        //edit old address
        url = 'user/edit-address';
        data.id = editingAddressId;
      }
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      // console.log('data signup ', data);
      let self = this;
      let token = this.$store.state.token;
      await fetch(apiUrl + url, {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          let messages = resData.messages;
          if (resData.status == 'error') {
            if (messages.common.length > 0) self.$data.errors.common = messages.common;
            else self.$data.errors.common = [];

            if (messages.street_address_1.length > 0)
              self.$data.errors.street_address_1 = messages.street_address_1;
            else self.$data.errors.street_address_1 = [];

            if (messages.country.length > 0) self.$data.errors.country = messages.country;
            else self.$data.errors.country = [];

            if (messages.city.length > 0) self.$data.errors.city = messages.city;
            else self.$data.errors.city = [];

            if (messages.state.length > 0) self.$data.errors.state = messages.state;
            else self.$data.errors.birthday = [];

            if (messages.zip_code.length > 0) self.$data.errors.zip_code = messages.zip_code;
            else self.$data.errors.zip_code = [];

            console.log('self.$data ', self.$data);
            self.$data.ajaxLoad.normal = false;
            return false;
          }
          // self.$router.push('/email-verify');
          // location.reload();
          self.getListAddressAgain();
          self.hideModal('modalAddress');
        })
        .catch((error) => {
          console.error('Error:', error);
        });
      this.$data.ajaxLoad.normal = false;
      console.log(this.$data);
    },
    editAddress: async function (event, id) {
      // modalAddressLabel
      // console.log('Id edit', id);
      this.$data.ajaxLoad.detail = true;
      this.$refs.modalAddressLabel.innerHTML = 'Edit new address';
      let bootstrap = require('bootstrap');
      var myModal = new bootstrap.Modal(document.getElementById('modalAddress'), {
        keyboard: false,
      });
      myModal.show();

      let apiUrl = process.env.VUE_APP_API_URL;
      let self = this;
      let data = {
        unique_id: this.$store.state.unique_id,
        editing_address_id: id,
      };
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      let token = this.$store.state.token;
      await fetch(apiUrl + 'user/get-address-detail', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          // self.$data.ajaxLoad.detail = false;
          let address = resData.address;
          this.$refs.gautocomplete.value = address.street_address_1;
          this.$refs.address2.value = address.street_address_2;
          this.$refs.city.value = address.city;
          this.$refs.state.value = address.state;
          this.$refs.zip_code.value = address.zip_code;
          this.$refs.country.value = address.country.toLowerCase();
          this.$refs.lng.value = address.longitude;
          this.$refs.lat.value = address.latitude;
          if (address.set_default == 1) {
            this.$refs.set_default.checked = true;
          } else {
            this.$refs.set_default.checked = false;
          }
        })
        .catch(function (err) {
          self.$data.ajaxLoad.detail = false;
          console.log('error', err);
        });
      self.$data.ajaxLoad.detail = false;
      this.$refs.editingAddressId.value = id;
    },
    deleteAddress: async function (event, id) {
      // modalAddressLabel
      // console.log('Id edit', id);
      let bootstrap = require('bootstrap');
      var myModal = new bootstrap.Modal(document.getElementById('modalDeleteAddress'), {
        keyboard: false,
      });
      myModal.show();
      this.$refs.deletetingAddressId.value = id;
    },
    btnAddAddressEvent: function () {
      this.$refs.gautocomplete.value = '';
      this.$refs.address2.value = '';
      this.$refs.city.value = '';
      this.$refs.state.value = '';
      this.$refs.zip_code.value = '';
      this.$refs.country.value = '';
      this.$refs.lng.value = '';
      this.$refs.lat.value = '';
      this.$refs.set_default.checked = false;
      // modalAddressLabel
      this.$refs.modalAddressLabel.innerHTML = 'Add new address';
      this.$refs.editingAddressId.value = '';
      let bootstrap = require('bootstrap');
      var myModal = new bootstrap.Modal(document.getElementById('modalAddress'), {
        keyboard: false,
      });
      myModal.show();
    },
    fillInAddress() {
      //reset address field
      //reset address field
      this.$refs.address2.value = '';
      this.$refs.city.value = '';
      this.$refs.state.value = '';
      this.$refs.zip_code.value = '';
      this.$refs.country.value = '';
      this.$refs.lng.value = '';
      this.$refs.lat.value = '';
      // Get the place details from the autocomplete object.
      const place = autocomplete.getPlace();
      let address2 = '';
      let zip_code = '';
      console.log('place ', place);

      // Get each component of the address from the place details,
      // and then fill-in the corresponding field on the form.
      // place.address_components are google.maps.GeocoderAddressComponent objects
      // which are documented at http://goo.gle/3l5i5Mr
      for (const component of place.address_components) {
        const componentType = component.types[0];

        switch (componentType) {
          case 'street_number': {
            address2 = `${component.long_name} ${address2}`;
            break;
          }

          case 'route': {
            address2 += component.long_name;
            break;
          }

          case 'postal_code': {
            zip_code = `${component.long_name}${zip_code}`;
            break;
          }

          case 'postal_code_suffix': {
            zip_code = `${zip_code}-${component.long_name}`;
            break;
          }
          case 'locality':
            // document.querySelector("#locality").value = component.long_name;
            this.$refs.city.value = component.long_name;
            break;

          case 'administrative_area_level_1': {
            this.$refs.state.value = component.long_name;
            break;
          }
          case 'country':
            this.$refs.country.value = component.short_name.toLowerCase();
            break;
        }
      }
      // address2Field.value = address2;
      // this.$refs.address2.value = address2;
      this.$refs.gautocomplete.value = address2;
      this.$refs.zip_code.value = zip_code;
      this.$refs.lng.value = place.geometry.location.lat();
      this.$refs.lat.value = place.geometry.location.lng();
      // this.$refs.address2.$el.focus();
      document.getElementById('street_address_2').focus();

      autocomplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ (this.$refs.gautocomplete),
        {
          componentRestrictions: { country: this.$refs.country.value },
          types: ['geocode'],
        }
      );
      autocomplete.addListener('place_changed', this.fillInAddress);
    },
    countryChange() {
      console.log('country change :countryChange', this.$refs.country.value);
      if (this.$refs.country.value == '') return;
      autocomplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ (this.$refs.gautocomplete),
        {
          componentRestrictions: { country: this.$refs.country.value },
          types: ['geocode'],
        }
      );
      autocomplete.addListener('place_changed', this.fillInAddress);
    },
    btnSaveAsMainAddressEvent: async function (event, id) {
      let apiUrl = process.env.VUE_APP_API_URL;
      let self = this;
      self.$data.ajaxLoad.list = true;
      let data = {
        unique_id: this.$store.state.unique_id,
        editing_address_id: id,
      };
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      let token = this.$store.state.token;
      await fetch(apiUrl + 'user/set-as-main-address', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }

          self.$data.ajaxLoad.list = false;
        })
        .catch(function (err) {
          self.$data.ajaxLoad.list = false;
          console.log('error', err);
        });
      self.$data.ajaxLoad.list = false;
      // location.reload();
      self.getListAddressAgain();
    },
    btnModalDeleteHandler: async function () {
      let apiUrl = process.env.VUE_APP_API_URL;
      let self = this;
      self.$data.ajaxLoad.list = true;
      let data = {
        unique_id: this.$store.state.unique_id,
        id: this.$refs.deletetingAddressId.value,
      };
      const formData = Object.keys(data)
        .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
        .join('&');
      let token = this.$store.state.token;
      await fetch(apiUrl + 'user/delete-address', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: formData,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }

          self.$data.ajaxLoad.list = false;
        })
        .catch(function (err) {
          self.$data.ajaxLoad.list = false;
          console.log('error', err);
        });
      self.$data.ajaxLoad.list = false;
      // location.reload();
      await self.getListAddressAgain();
      self.hideModal('modalDeleteAddress');
    },
    getListAddressAgain: async function () {
      let apiUrl = process.env.VUE_APP_API_URL;
      let self = this;
      self.$data.countryList = [];
      let token = this.$store.state.token;
      let unique_id = this.$store.state.unique_id;
      await fetch(apiUrl + 'user/get-user-address-list', {
        method: 'POST',
        headers: {
          // 'Accept': 'application/json',
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
        },
        body: `unique_id=${unique_id}`,
      })
        .then((response) => response.json())
        .then((resData) => {
          console.log('Success:', resData);
          // let messages = resData.messages
          if (resData.status == 'error') {
            alert('error while geting data ' + resData.message);
            return;
          }
          self.$data.addressList = resData.addresses;
        })
        .catch(function (err) {
          console.log('error', err);
        });
    },
    hideModal: function (modalId) {
      // let bootstrap = require('bootstrap');
      // var myModal = new bootstrap.Modal(document.getElementById(modalId), {
      //   keyboard: false,
      // });
      // myModal.hide();
      let bootstrap = require('bootstrap');
      var myModal = bootstrap.Modal.getInstance(document.getElementById(modalId), {
        keyboard: false,
      });
      myModal.hide();
    },
  },
  async created() {
    let self = this;
    self.$data.ajaxLoad.list = true;
    self.$data.addressList = [];
    let apiUrl = process.env.VUE_APP_API_URL;
    let promiseCountryList = fetch(apiUrl + 'common/custom-country-list', { method: 'GET' }).then(
      (response) => response.json()
    );
    let token = this.$store.state.token;
    let unique_id = this.$store.state.unique_id;
    let promiseAddressList = fetch(apiUrl + 'user/get-user-address-list', {
      method: 'POST',
      headers: {
        // 'Accept': 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded', // application/json application/x-www-form-urlencoded multipart/form-data; boundary=something
      },
      body: `unique_id=${unique_id}`,
    }).then((response) => response.json());
    await Promise.all([promiseCountryList, promiseAddressList])
      .then((resData) => {
        console.log(resData);
        if (resData[0].status == 'error') {
          alert('error while geting data ' + resData[0].message);
          return;
        }
        self.$data.countryList = resData[0].countryList;
        if (resData[1].status == 'error') {
          alert('error while geting data ' + resData[1].message);
          return;
        }
        self.$data.addressList = resData[1].addresses;
        self.$data.ajaxLoad.list = false;
      })
      .catch((err) => {
        console.error('Error:', err);
        self.$data.ajaxLoad.list = false;
      });
    const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
    this.$loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`
    )
      .then(() => {})
      .catch(() => {
        // Failed to fetch script
      });
  },
  mounted() {
    window.initMap = () => {
      autocomplete = new window.google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ (this.$refs.gautocomplete),
        { types: ['geocode'] }
      );
      autocomplete.addListener('place_changed', this.fillInAddress);
    };
  },
  unmounted() {
    const key = process.env.VUE_APP_GOOGLEMAPS_KEY;
    this.$unloadScript(
      `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places&callback=initMap`
    )
      .then(() => {})
      .catch(() => {});
  },
};
</script>

<style scoped>
/*.modal {*/
/*  z-index: 20;*/
/*}*/
/*.modal-backdrop {*/
/*  z-index: 10;*/
/*}*/
</style>
